import { createRouter,createWebHashHistory  } from 'vue-router'


import HomeView from '../views/HomeView.vue'

const router = createRouter({
history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'HomeView',
      component: HomeView,
    },
    {
      path: '/AboutView',
      name: 'AboutView',
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/CoreProduct',
      name: 'CoreProduct',
      component: () => import('../views/CoreProduct.vue')
    },
    {
      path: '/DetailsView',
      name: 'DetailsView',
      component: () => import('../views/DetailsView.vue')
    },
    {
      path: '/SolutionAssembly',
      name: 'SolutionAssembly',
      component: () => import('../views/SolutionAssembly.vue')
    },
    {
      path: '/ApplicationDetails',
      name: 'ApplicationDetails',
      component: () => import('../views/ApplicationDetails.vue')
    },
    {
      path: '/SolutionView',
      name: 'SolutionView',
      component: () => import('../views/SolutionView.vue')
    },
  ],
  scrollBehavior() {
    return { top: 0 }; // 将滚动位置重置为顶部
  }
})

export default router