<template>
    <footer class="footer-bar flex-center" v-cloak>
        <div class="container main">
            <h1 class="container-h">联系我们</h1>
            <div class="container-main">
                <el-row class="container-main-r" :gutter="[40,{ xs:20, sm:16, md:16, lg:20, xl:40 }]">
                    <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                        <div class="container-h-lx">
                            <span>咨询电话</span>
                            <span>13352978305 （李经理）</span>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                        <el-row class="container-box" :gutter="[{ xs:8, sm:16, md:50, lg:50, xl:40 },{ xs:16, sm:16, md:16, lg:20, xl:40 }]">
                            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <div class="container-h-dz">
                                    <div class="dz-s">
                                        <span>深圳总部</span>
                                    </div>
                                    <div class="dz-h">
                                        <span>广东省深圳市南山区深南大道10128号数字文化产业基地东塔楼2401-2402</span>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <div class="container-h-dz">
                                    <div class="dz-s">
                                        <span>东莞公司</span>
                                    </div>
                                    <div class="dz-h">
                                        <span>广东省东莞市南城寰宇汇金中心ucc2号楼1415</span>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <div class="container-h-dz">
                                    <div class="dz-s">
                                        <span>西安公司</span>
                                    </div>
                                    <div class="dz-h">
                                        <span>陕西省西安市碑林区铁安三街35栋2层</span>
                                    </div>
                                </div>
                            </el-col>
                            
                        </el-row>
                    </el-col>
                    </el-row>
                    <el-row justify="space-between" :gutter="[40,{ xs:20, sm:16, md:16, lg:20, xl:40 }]">
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <div class="container-h-code">
                                <div class="container-h-code-i">
                                    <img src="../assets/imgae/global-ewm.png" alt="">
                                </div>
                                <span>公司公众号</span>
                            </div>
                        </el-col>
                    </el-row>
            </div>
            <div class="container-y">
                <div class="container-y-t">
                    <span>深圳市威斯登信息科技有限公司</span>
                    <span>Copyright© <a href="https://beian.miit.gov.cn" target="_blank">  粤ICP备19141537号-1</a></span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { ElRow } from 'element-plus';
export default {
    name: 'FooterBar',
    components: {
        "el-row": ElRow
    },
    setup() {





        return {

        }
    }
}

</script>

<style lang="scss" scoped>
@font-face {
   /* 思源黑体 */
  font-family: "SourceHanSansCN";
  src: url('../assets/fonts/SourceHanSansCN-Light.otf');
  font-weight: normal;
  font-style: normal;
}
    .footer-bar{
        width: 100%;
        min-height: 315px;
        background: #0F1012;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 24px;

        .main{
            padding-right: 27px;
        }
        .container-main{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            box-sizing: border-box;
            align-items: center;

            .container-main-r{
                flex: 1;
                max-width: 1100px;
            }
        }
        .container-h{
            color: #ffffff;
            font-size: 18px;
            font-weight: 500;
            padding-left: 8px;
            border-left: 5px solid #FFFFFF;
            height: 25px;
        }
        .container-h-lx{
            max-width: 265px;
            height: 62px;
            display: flex;
            justify-content: space-between;
            color: #FFFFFF;
            font-size: 14px;
            padding: 18px 14px 0px 14px;
            margin-top: 18px;
            box-sizing: border-box;
            font-family: "SourceHanSansCN";
        }
        .container-box{
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            // justify-content: space-around;
        }
        .container-h-dz{
            width: 338px;
            height: 78px;
            padding: 10px 10px 0px 10px;
            border-top: 1px solid #A0A2AA;
            color: #FFFFFF;
            display: flex;
            font-size: 12px;
            box-sizing: border-box;
            margin-right: 26px;
            .dz-s{
                font-weight: 500;
                margin-right: 24px;
                line-height: 20px;
                width: 58px;
                box-sizing: border-box;
                font-size: 14px;
            }
            .dz-h{
                flex: 1;
                line-height: 20px;
                font-size: 14px;
                font-family: "SourceHanSansCN";
            }
        }
        @include respond-to("desktop") {
            .container-box{
                display: flex;
                justify-content: 'center'
            }
        }
        .container-h-code{
            width: 110px;
            height: 138px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            color: #ffffff;
            font-family: "SourceHanSansCN";
            .container-h-code-i{
                max-width: 90px;
                max-height: 90px;
                transition: all 0.3s;
            -webkit-transition: all 0.3s;

                
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                }
            }
            .container-h-code-i:hover{
                -webkit-transform: translateY(-10px);
            }
        }
        .container-y{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 39px;
            .container-y-t{
                    flex: 1;
                    text-align: center;
                    font-family: "SourceHanSansCN";
                    font-size: 14px;
                    color: #A0A2AA;
                    
                }
        }
        @include respond-to("desktop") {
            .container-y{
                margin-top: 18px!important;
            }
        }
        @include respond-to("desktop") {
            .container-y{
                margin-top: 18px!important;
            }
        }
    }
    @include respond-to("phone") {
        .footer-bar{
            display: flex;
            justify-content: center;
            align-items: center;

            .container-main{
                flex: 1;
                display: flex;
                justify-content: center !important;
                align-items: center  !important;
            }

            .container-y{
                width: 100%;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;

                .container-y-t{
                    width: 188px;
                    line-height: 14px;
                    text-align: center;
                }
            }
            .container{
                --bs-gutter-x: 56px !important;
            }
        }
    }
</style>