<template>
    <div class="banner">
      <div class="banner-box">
        <div class="banner-fixed" style="transform: translate(0px, 0px); visibility: visible;">
          <swiper
            class="banner-wrapper"
            :modules="modules"
            :direction="'horizontal'"
            :centeredSlides="true"
            :options="swiperOptions"
            :autoplay="{ delay: 5750, disableOnInteraction: false }"
            :noSwipingSelector="'banner-wrapper'"
            :effect="'fade'"
            :fadeEffect="{crossFade: true}"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            ref="swiperRef"
          >
            <SwiperSlide class="banner-wrapper-item" :style="swiperIndex === index ? {'pointer-events': 'auto','z-index': 2} : ''" v-for="(item,index) in imgagArr" :key="index">
              <div class="banner-item-bg">
                <img :src="item.banner">
              </div>
              <div class="banner-item-detail">
                <div :style="swiperIndex !== index ? {
                    'opacity': '0',
                    'transform': 'translate(0,2.1875rem)',
                    'transition': 'all 1s'
                  }: {
                  'opacity': '1',
                  'transform': 'translate(0,0)',
                  'transition': 'all 1s'
                  }">
                  <router-link :to="{path: item.path, query: { param: item.title, identifying: item.identifying }}" @click="onDetail(item.path)">
                    <img :src="item.bannerText">
                  </router-link>
                </div>
              </div>
            </SwiperSlide>
        </swiper>

        <div class="banner-control">
          <div class="banner-control-pagination">
            <div class="control-pagination-item" v-for="(item,index) in imgagArr" :key="index">
              <div :class="swiperIndex == index ? 'active': 'item-active'"></div>
            </div>
          </div>
          <div class="banner-control-thumbnail">
            <div class="banner-control-thumbnail-split"></div>
            <div class="banner-control-thumbnail-list">
              <div class="banner-control-thumbnail-item" :class="{ active: swiperIndex === index  }" v-for="(item,index) in bannerArr" :key="index">
                  <img :src="item.url" @click="onBanner">
              </div>
            </div>
            <div class="banner-control-thumbnail-next">
              <div class="banner-control-thumbnail-next-inner">
                <div class="banner-control-thumbnail-next-bg" @click="goToNextSlide">
                  <div class="banner-control-thumbnail-next-content">
                    <span>NEXT</span>
                    <div class="banner-control-thumbnail-next-arrow">
                      <div>
                        <img src="../assets/imgae/next-icon.svg" />
                        <img src="../assets/imgae/next-icon.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="thumbnail-next-01" :class="{ active: timer1 }">
                <div></div>
              </div>
              <div class="thumbnail-next-02" :class="{ active: timer2 }">
                <div></div>
              </div>
              <div class="thumbnail-next-03" :class="{ active: timer3 }">
                <div></div>
              </div>
              <div class="thumbnail-next-04" :class="{ active: timer4 }">
                <div></div>
              </div>
              <div class="thumbnail-next-05" :class="{ active: timer5 }">
                <div></div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <!-- <VideoModal /> -->
        
      </div>
      <div class="banner-mian">
        <div class="banner-mian-content">
          <div class="banner-mian-content-t" v-animate-onscroll="{down: 'slide-in-bottom'}">
            <span class="text-j">解决方案</span><p></p>
            <span class="text-s">solution</span>
          </div>
          <div class="content-m"  v-animate-onscroll="{down: 'slide-in-bottom'}">
            <div class="content-box" v-for="(v,i) in data" :key="i">
              <div class="content-box-img">
                <img :src="v.url" alt="">
              </div>
              <div class="content-box-text">
                <div>
                  <div class="content-box-text-t">
                    <span>{{ v.title }}</span>
                    <div class="content-box-text-t-line"></div>
                  </div>
                  <div class="content-box-text-x">
                    <span>{{ v.text }}</span>
                  </div>
                </div>
                
                <router-link :to="{path: v.path, query: { param: v.title, identifying: v.identifying }}">
                  <div class="content-box-text-btn" @click="onClick()">
                    <span>点击了解详情</span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="banner-mian-btn flex-center">
          <div class="mian-btn-box flex-column" v-animate-onscroll="{down: 'slide-in-bottom'}">
              <div class="banner-mian-content-t">
                <span class="text-j">合作品牌</span>
                <p></p>
                <span class="text-s">Cooperation Brand</span>
              </div>
              <div  class="mian-btn-box-m">

                <div class="mian-btn-img"  v-for="(item, index) in imgData" :key="index">
                  <img :src="item" alt="">
                </div>

              </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from "vue";
// import VideoModal from "@/components/VideoModal.vue"
import heom1 from "../assets/imgae/heom1.png"
import heom2 from "../assets/imgae/heom2.png"
import heom3 from "../assets/imgae/heom3.png"
// import heom4 from "../assets/imgae/heom4.png"
import heom5 from "../assets/imgae/heom5.png"
// import heom6 from "../assets/imgae/heom6.png"
import A05 from "../assets/imgae/A05-1.png"
import A052 from "../assets/imgae/A05-2.png"
import A053 from "../assets/imgae/A05-3.png"
import A054 from "../assets/imgae/A05-4.png"
import B05 from "../assets/imgae/B05-1.png"
import B052 from "../assets/imgae/B05-2.png"
import B053 from "../assets/imgae/B05-3.png"
import B054 from "../assets/imgae/B05-4.png"
import C05 from "../assets/imgae/C05-1.png"
import C052 from "../assets/imgae/C05-2.png"
import C053 from "../assets/imgae/C05-3.png"
import C054 from "../assets/imgae/C05-4.png"
import D05 from "../assets/imgae/D05-1.png"
import D052 from "../assets/imgae/D05-2.png"
import D053 from "../assets/imgae/D05-3.png"
import D054 from "../assets/imgae/D05-4.png"
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay,EffectFade } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

  export default {
    name: 'HomeView',
    components:{ 
      Swiper,
      SwiperSlide
    },
    setup() {
        const videoUrl = ref(null)
        const swiperRef = ref(null);
        const swipers = ref(null);
        const myImage = ref(null);
        const swiperIndex = ref(0)
        const falgDetail = ref(false)
        const data = reactive([
            {
              url: heom1,
              title: '智慧铁路',
              text: '涵盖客运服务、货运服务、商旅出行、人事管理、劳卫管理等多个业务板块，以数字化手段实现铁路行业生产、管理、服务活动的流程化、标准化和规范化,有效降低铁路企业的运营成本，增强企业的核心竞争力。 ',
              path: '/SolutionAssembly',
              identifying: '0'
            },
            {
              url: heom2,
              title: '智慧文旅',
              text: '为多单位、多业态的文旅集团公司提供一体化综合票务管理解决方案。帮助客户从直营到分销、从线上到线下，搭建完整的票务管理平台，实现园区票务管理、园区商户管理，领导决策管理及旅游大数据管理。',
              path: '/SolutionAssembly',
              identifying: '1'
            },
            {
              url: heom3,
              title: '智慧工地',
              text: '以AI和IoT两大技术为基础，打造AIoT数字化底座，并通过全方位的智能硬件（Edge）落地，以生态化方式为智慧城市数字底座提供一站式数字物联网智能解决方案，赋能智慧城市毛细血管。',
              path: '/SolutionAssembly',
              identifying: '2'
            },
            // {
            //   url: heom4,
            //   title: '智慧党建',
            //   text: '是一款通过“碎片化学习、系统性认知、社交化管理、精细化服务”四大核心应用理念，为组织部、街道、机关、大型院校以及行业协会解决党的自身建设与为民服务的综合性应用系统。',
            //   path: '/SolutionView'
            // },
            {
              url: heom5,
              title: '智慧医院',
              text: '利用移动互联网、大数据、人工智能等新一代信息技术，实现对患者、陪护、职工等人群进行就诊码、身份证等身份自动识别，院内轨迹追查、实时掌控人员流动情报，确保安防工作无死角。',
              path: '/SolutionAssembly',
              identifying: '3'
            },
            // {
            //   url: heom6,
            //   title: '一体化集成平台',
            //   text: '平台统一接入、信息融合、安全规范、集成共享四大总体特色。',
            //   path: '/SolutionView',
            //   identifying: '0'
            // },
        ])
        const imgData = reactive([A05,A052,A053,A054,B05,B052,B053,B054,C05,C052,C053,C054,D05,D052,D053,D054,])
        const imgagArr = reactive([
          {
            banner: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner1.png',
            bannerText: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner1-1.png',
            title: '',
            path: '',
            identifying: ''
          },
          {
            banner: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner2.png',
            bannerText: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner2-1.png',
            title: '智慧铁路',
            path: '/SolutionAssembly',
            identifying: '0'
          },
          {
            banner: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner3.png',
            bannerText: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner3-1.png',
            title: '智慧工地',
            path: '/SolutionAssembly',
            identifying: '2'
          },
          {
            banner: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner4.png',
            bannerText: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner4-1.png',
            title: '智慧医院',
            path: '/SolutionAssembly',
            identifying: '3'
          },
          {
            banner: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner5.png',
            bannerText: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner5-1.png',
            title: '智慧铁路',
            path: '/SolutionAssembly',
            identifying: '0'
          },
          {
            banner: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner6.png',
            bannerText: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner6-1.png',
            title: '人脸识别终端系列',
            path: '/CoreProduct',
            identifying: 0
          },
          {
            banner: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner7.png',
            bannerText: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banne7-1.png',
            title: '便携式终端系列',
            path: '/CoreProduct',
            identifying: 1
          },
          {
            banner: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner8.png',
            bannerText: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banne8-1.png',
            title: '自助闸机系列',
            path: '/CoreProduct',
            identifying: 2
          },
        ])
        const bannerArr = reactive([
          {
            url: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner-2-3.png'
          },
          {
            url: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner3-3.png'
          },
          {
            url: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner4-3.png'
          },
          {
            url: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner5-3.png'
          },
          {
            url: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner6-3.png'
          },
          {
            url: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner7-3.png'
          },
          {
            url: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner8-3.png'
          },
          {
            url: 'https://www.wsdminfo.com/adminimgs/CameraRoll/20231026/banner1-3.png',
          },])
        const bannerImg = ref('')
        const timer1 = ref(false)
        const timer2 = ref(false)
        const timer3 = ref(false)
        const timer4 = ref(false)
        const timer5 = ref(false)
        const swiperOptions = reactive(
            {
          // your swiper options here...
          loop: true,
        },
        ) 

        let timer1Id, timer2Id, timer3Id, timer4Id, timer5Id;

        const clearTimers = () => {
          clearTimeout(timer1Id);
          clearTimeout(timer2Id);
          clearTimeout(timer3Id);
          clearTimeout(timer4Id);
          clearTimeout(timer5Id);
        };
        const timer1Name = () => {
          timer1.value = true
          timer2Id = setTimeout(timer2Name, 750);
        }
        const timer2Name = () => {
          timer2.value = true
          timer3Id = setTimeout(timer3Name, 1500)
        }
        const timer3Name = () => {
          timer3.value = true
          timer4Id = setTimeout(timer4Name, 1500)
        }
        const timer4Name = () => {
          timer4.value = true
          timer5Id = setTimeout(timer5Name, 1500)
        }
        const timer5Name = () => {
          timer5.value = true
        }
        const onBanner = () => {
          goToNextSlide()
        }
        const onDetail = (path) => {
          if(path) {
            if(path === '/SolutionAssembly') {
              sessionStorage.setItem('indexMenu', 1);
            } else {
              sessionStorage.setItem('indexMenu', 2);
            }
          }
        }
        onMounted(() => {
          
          watch(() => swipers.value?.activeIndex, (newIndex) => {
            let realIndex = newIndex;
            let totalSlides = 4
            
            if (newIndex >= totalSlides) {
              realIndex = 0; // reset index to 0 if it exceeds the number of slidesoldIndex
            }
            
            console.log(realIndex); // prints the correct index
          });
          timer1Id = setTimeout(timer1Name);
        });
        
        const onClick = () => {
          sessionStorage.setItem('indexMenu', 1);
        }
        const onSwiper = (swiper) => {
          swipers.value = swiper;
        };
        const onSlideChange = (swiper) => {
          swiperIndex.value = swiper.activeIndex;
          bannerImg.value = getItemByIndex(bannerArr, swiperIndex.value);
        };
        const goToNextSlide = () => {
          if(swiperIndex.value === 7) {
            swipers.value.slideTo(0);
            clearTimers()
          } else {
            swipers.value.slideNext(); // 切换至下一张图
            clearTimers()
          }
          
          
        };
        const timerName = () => {
          timer1.value = false
          timer2.value = false
          timer3.value = false
          timer4.value = false
          timer5.value = false
          setTimeout(timer1Name,50)
        }
        
        watch(
          () => swiperIndex.value,
          (newVal) => {
            if(newVal === 0 || newVal) {
              
              timerName()
            }
          }
        )
        const getItemByIndex = (array, index) => {
          // 判断索引是否有效
          if (index >= 0 && index < array.length) {
            return array[index];
          } else {
            return null; // 或者可以返回一个默认值或错误提示
          }
        }
        watch(
          () => swiperRef.value,
          (newVal, ) => {
            if (newVal !== null && newVal.$swiper) {
              const swiper = newVal.$swiper;
              console.log(swiper); // 打印 Swiper 实例
              // 进行其他操作...
            }
          }
        );

        return {
          swiperRef,
          videoUrl,
          data,
          imgData,
          onClick,
          onSwiper,
          onSlideChange,
          modules: [Autoplay,EffectFade],
          myImage,
          swiperIndex,
          imgagArr,
          falgDetail,
          goToNextSlide,
          swiperOptions,
          
          timer1,
          timer2,
          timer3,
          timer4,
          timer5,
          timer1Name,
          timer2Name,
          timer3Name,
          timer4Name,
          timer5Name,
          timerName,
          bannerArr,
          getItemByIndex,
          bannerImg,
          clearTimers,
          onBanner,
          onDetail
        }
    }
  }
</script>

<style lang="scss" scoped>
@font-face {
   /* 思源黑体 */
  font-family: "SourceHanSansCN";
  src: url('../assets/fonts/SourceHanSansCN-Light.otf');
  font-weight: normal;
  font-style: normal;
}
  .banner{
    width: 100%;
    height: auto;
    // position: relative;
    .videoPlayer-v{
      height: 100vh;
      width: 100%;
      // padding: 100px 100px;
      background: pink;
      margin-top: 88px;
    }
    .banner-box{
      margin-top: 92px;
      width: 100%;
      overflow: hidden;

      .banner-fixed{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        min-width: 1240px;
        
        .banner-wrapper{
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          pointer-events: none; 
          
          .banner-wrapper-item{
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            pointer-events: none;
            

            .banner-item-bg{
              width: 100%;
              height: 100%;
              overflow: hidden;
              img{
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .banner-item-detail{
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate(0,-50%);
              z-index: 999;
              .image-container{
                opacity: 0;
                transform: translate(0,20);
                transition: all 1s;
              }
              .image-container-flag{
                opacity: 1;
                transform: translate(0,0);
                transition: all 1s;
              }
              .image-container img{
                max-width: 100%;
              }
              // img{
              //   width: 100%;
              //   height: 100%;
              //   object-fit: cover;
              // }
            }
            @include respond-to("phone"){
              .banner-item-detail{
                display:flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 50%;
                left: 0;

                
              }
            }
          }
        }
        .banner-control{
          position: absolute;
          right: 141px;
          bottom: 58px;
          z-index: 101;
          width: 558px;
          height:203px;
          box-sizing: border-box;

          .banner-control-pagination{
            display: flex;
            margin-left: 32px;

            .control-pagination-item{
              padding: 13px 4px;
              cursor: pointer;

              .active{
                background-image: url('../assets/imgae/banner-icon-active.svg');
                width: 9px;
                height:9px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
              }
              .item-active{
                width: 9px;
                height:9px;
                background-image: url('../assets/imgae/banner-icon.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
              }
            }
          }
          .banner-control-thumbnail{
            display: flex;
            height:176px;
            box-sizing: border-box;
            .banner-control-thumbnail-split{
                width: 1px;
                height: 100%;
                background: #fff;
                margin-right:34px;
            }
            .banner-control-thumbnail-list{
              max-width: 310px;
              max-height:176px;
              flex:1;
              // background: pink;
              margin-right:34px;
              border: 1px solid #fff;
              box-sizing: border-box;
              position: relative;

              .banner-control-thumbnail-item{
                position: absolute;
                left: 0;
                right: 0;
                visibility: hidden;
                opacity: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                cursor: pointer;
                transition: all .8s;
              }
              .banner-control-thumbnail-item img{
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .banner-control-thumbnail-item.active{
                visibility: visible;
                opacity: 1;
                z-index: 2;
              }
            }
            .banner-control-thumbnail-next{
              position: relative;
              box-sizing: border-box;
              width: 176px;
              height: 176px;
              cursor: pointer;
              
              .thumbnail-next-01{
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 1px;
                overflow: hidden;
              }
              .thumbnail-next-01 div {
                  position: relative;
                  right: -1px;
                  width: 100%;
                  height: 100%;
                  transform: translate(100%,0);
                  background-color: #fff;
                  // transition: transform 0.75s linear, background-color 0.75s linear;
              }
              .thumbnail-next-01.active div,.thumbnail-next-05.active div {
                transform: translate(0,0);
                transition: transform 0.75s linear
              }
              .thumbnail-next-02{
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 100%;
                overflow: hidden;
              }
              .thumbnail-next-02 div {
                  position: relative;
                  top: -1px;
                  width: 100%;
                  height: 100%;
                  transform: translate(0,-100%);
                  background-color: #fff;
              }
              .thumbnail-next-03{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                overflow: hidden;
              }
              .thumbnail-next-03 div {
                  position: relative;
                  left: -1px;
                  width: 100%;
                  height: 100%;
                  transform: translate(-100%,0);
                  background-color: #fff;
              }
              .thumbnail-next-04{
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                overflow: hidden;
              }
              .thumbnail-next-04 div {
                  position: relative;
                  bottom: -1px;
                  width: 100%;
                  height: 100%;
                  transform: translate(0,100%);
                  background-color: #fff;
              }
              .thumbnail-next-02.active div,.thumbnail-next-03.active div,.thumbnail-next-04.active div{
                transform: translate(0,0);
                transition: transform 1.5s linear;
              }
              .thumbnail-next-05{
                position: absolute;
                top: 0;
                right: 0;
                width: 50%;
                height: 1px;
                overflow: hidden;
              }
              .thumbnail-next-05 div {
                  position: relative;
                  right: -1px;
                  width: 100%;
                  height: 100%;
                  transform: translate(100%,0);
                  background-color: #fff;
              }
            }
            .banner-control-thumbnail-next-inner{
              position: relative;
              box-sizing: border-box;
              border: 1px solid rgba(255,255,255,.3);
              background: rgba(229, 229, 229, 0.5);
              width: 100%;
              height: 100%;
              transition: all .6s;
              -webkit-backdrop-filter: blur(3px);
              backdrop-filter: blur(3px);

              .banner-control-thumbnail-next-bg{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .banner-control-thumbnail-next-content{
                  display: flex;
                  align-items: center;
                  line-height: 44px;
                  color: #fff;
                  font-size: 22px;
                  font-weight: 400;
                  span{
                    margin-right: 4px;
                  }

                  .banner-control-thumbnail-next-arrow{
                    width:32px;
                    height:32px;
                    overflow: hidden;
                  }
                  .banner-control-thumbnail-next-arrow div{
                    display: flex;
                    transition: all 0.1s ease-out;
                  }
                  

                  .banner-control-thumbnail-next-arrow img{
                    width:32px;
                    height:32px;
                    margin-right: 12px;
                  }
                  .banner-control-thumbnail-next-arrow img:last-child {
                      margin-right: 0;
                  }
                }
              }
            }
            .banner-control-thumbnail-next-inner:hover{
              .banner-control-thumbnail-next-arrow div {
                animation: slideRight 1s ease infinite;
              }
            }
            @keyframes slideRight {
              0% {
                transform: translate(-2.678125rem,0);
              }
              100% {
                visibility: inherit;
                transform: translate(0,0);
              }
            }
          }
        }
      }
      @include respond-to("phone"){
        .banner-fixed{
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            min-width: 375px!important;
            height: 500px;
            
            .banner-control{
              display: none;
            }
            
        }
      }
    }

    .banner-mian{
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      background: #F5F5F5;

      .banner-mian-content{
        max-width: 1200px;
        height: 888px;
        display: flex;
        // justify-content: center;
        // align-items: center;
        flex-direction: column;
        padding-bottom: 27px;
        // padding-top: 47px;


        .banner-mian-content-t{
          color: #212121;
          text-align: center;
          margin-bottom: 26px;
          .text-j{
            font-size: 24px;
            font-weight: 700;
            
          }
          .text-s{
            font-size: 14px;
            font-weight: 700;
            margin-top: 16px;
            display: block;
          }
        }
        .content-m{
          max-width: 1200px;
          display: flex;
          
          align-items: center;
          height: 700px;
          .content-box{
            // width: 280px;
            flex:1;
            height: 480px;
            margin-bottom: 28px;
            border-radius: 4px;
            background: #FFFFFF;
            transition: all 0.3s;
            -webkit-transition: all 0.3s;
            margin:10px 10px;

            .content-box-img{
              width: 100%;
              height: 130px;
              position: relative;
               img{
                width: 100%;
                height: 130px;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
               }
            }
            .content-box-text{
              flex: 1;
              height: 350px;
              padding: 18px 10px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;

              div{
                .content-box-text-t{
                font-size: 18px;
                font-weight: 500;
                color: #2C2C34;
                height: 42px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                .content-box-text-t-line{
                  width: 33px;
                  height: 2px;
                  background: #212121;
                }
              }
              .content-box-text-x{
                font-size: 14px;
                color: #A0A2AA;
                line-height: 20px;
                margin-top: 16px;
                height: 100px;
                font-weight: 400;
                margin-bottom: 16px;
                font-family: "SourceHanSansCN";
              }
              }
              
              .content-box-text-btn{
                width: 144px;
                height: 32px;
                border-radius: 3px;
                background: #2C7EF9;
                color: #FFFFFF;
                font-size: 14px;
                // margin-top: 16px;
                line-height: 30px;
                text-align: center;
                transition: all 0.3s;
                -webkit-transition: all 0.3s;
                font-family: "SourceHanSansCN";
              }
              
            }
          }
          .content-box:hover {
              box-shadow: 5px 0px 20px 0px #81A3D7;
              // transform: translate(2px, -3px) scale(1.1);
              -webkit-transform: translateY(-10px);
              cursor: pointer;
              .content-box-text{
                  background: #2C7EF9;
                  border-bottom-right-radius: 4px;
                  border-bottom-left-radius: 4px;

                  div{
                    .content-box-text-t{
                    color: #ffffff;
                    overflow: hidden;
                    white-space: nowrap;
                    // animation: typewriter 1s steps(5) forwards;
                    // @keyframes typewriter {
                    //   from { width: 98%; }
                    //   to { width: 100%; }
                    // }
                    .content-box-text-t-line{
                      background: #ffffff;
                    }
                  }
                  .content-box-text-x{
                    animation: text-fade 0.2s forwards;
                    animation-delay: 0.1s;

                    @keyframes text-fade {
                      0% { color: #A0A2AA; }
                      100% { color: white; }
                    }
                  }
                  }

                  
                }
                .content-box-text-btn{
                  color: #2C7EF9;
                  background: #FFFFFF;
                  cursor: pointer;
              }
              .content-box-img::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, #2C7EF9 0%, rgba(44,126,249,0.00) 100%);
              }
          }
        }
      }

      .banner-mian-btn{
        background: #FFFFFF;
        width: 100%;
        height: auto;

        .mian-btn-box{
          max-width: 1200px;
          padding: 50px 0px 70px 0px;

          .banner-mian-content-t{
              color: #212121;
              text-align: center;
              margin-bottom: 26px;
              .text-j{
                font-size: 24px;
                font-weight: 700;
                
              }
              .text-s{
                font-size: 14px;
                font-weight: 700;
                margin-top: 16px;
                display: block;
              }
            }
            .mian-btn-box-m{
              width: 1092px;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              // margin-top: 20px;
              padding: 20px 32px;

              .mian-btn-img{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 20px;
                // box-sizing: border-box;
                img{
                  width: 200px;
                  height: 100px;
                  transition: all 0.3s;
                  -webkit-transition: all 0.3s;
                }
                img:hover{
                box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
                -webkit-transform: translateY(-3px);
              }
              }
              
            }
        }
      }
    }
  }
@include respond-to("phone") {
  .mian-btn-box-m{
    padding: 0px !important;
    width: 375px!important;
  }
  .mian-btn-img{
    width: 150px !important;
    height: 60px !important;
    padding: 22px 4px !important;
  }
  .videoPlayer-v{
    height: 690px;
    width: 100%;
  }
  .banner-mian-content{
    padding-top: 47px;
    height: 100%!important;
    .content-m{
      width: 100%;
      height: 2000px !important;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
@include respond-to("pad"){
  .mian-btn-img{
    width: 88px !important;
    height: 40px !important;
    padding: 10px 4px !important;
  }
  .videoPlayer-v{
    height: 690px;
    width: 100%;
  }
  .banner-mian-content{
    padding-top: 47px;
    height: 100%!important;
    .content-m{
      width: 100%;
      // height: 1800px!important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .mian-btn-box-m{
      width: 1000px!important;
    }
  .banner-mian-content-t{
          color: #212121;
          text-align: center;
          margin-bottom: 26px;
          .text-j{
            font-size: 24px;
            font-weight: 700;
            
          }
          .text-s{
            font-size: 14px;
            font-weight: 700;
            margin-top: 16px;
            display: block;
          }
        }

}
@include respond-to("notebook"){
  .mian-btn-img{
    padding: 20px 30px !important;
  }
  .videoPlayer-v{
    height: 690px;
    width: 100%;
  }
  .banner-mian-content{
    padding-top: 47px;
  }
  .videoPlayer-v{
      height: 100%;
      width: 817px;
    }
    .banner-mian-content{
    padding-top: 47px;
    height: 100%!important;
    .content-m{
      width: 100%;
      // height: 1800px!important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
  }
  .mian-btn-box-m{
      width: 1100px!important;
    }
  .banner-mian-content-t{
          color: #212121;
          text-align: center;
          margin-bottom: 26px;
          .text-j{
            font-size: 24px;
            font-weight: 700;
            
          }
          .text-s{
            font-size: 14px;
            font-weight: 700;
            margin-top: 16px;
            display: block;
          }
        }

}
@include respond-to("desktop"){
  .banner-mian-content{
    padding-top: 47px;
  }
  .mian-btn-img{
    width: 140px !important;
    height: 70px !important;
  }
  .mian-btn-img{
    padding: 20px 30px!important;
  }
  .banner-mian-content{
    padding-top: 47px;
    height: 734px!important;
    .content-m{
      width: 100%;
      height: 570px!important;
      display: flex;
      // justify-content: center;
      align-items: center;
    }
  }
  .mian-btn-box-m{
      width: 1194px!important;;
      .mian-btn-img{
          display: flex;
          align-items: center;
          justify-content: center;
          // padding: 10px 20px !important;
          width: 200px!important;
          height: 100px!important;
          img{
            width: 200px!important;
            height: 100px!important;
          }
        }
    }
  .banner-mian-content-t{
          color: #212121;
          text-align: center;
          margin-bottom: 26px;
          .text-j{
            font-size: 24px;
            font-weight: 700;
            
          }
          .text-s{
            font-size: 14px;
            font-weight: 700;
            margin-top: 16px;
            display: block;
          }
        }
  
}
@include respond-to("tv"){

  .mian-btn-img{
    width: 200px !important;
    height: 100px !important;
  }
  .banner-mian-content{
    padding-top: 47px;
  }
  .banner-mian-content-t{
          color: #212121;
          text-align: center;
          margin-bottom: 26px;
          .text-j{
            font-size: 24px;
            font-weight: 700;
            
          }
          .text-s{
            font-size: 14px;
            font-weight: 700;
            margin-top: 16px;
            display: block;
          }
        }

        .custom-col {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .custom-col {
    width: 16.66667%; /* 6个列占据一行 */
  }
}

@media (max-width: 767px) {
  .custom-col {
    width: 20%; /* 5个列占据一行 */
  }
}
  
}
</style>