<template>
    <div>
        <header class="navigation-bar flex-center" :class="{'sticky':isSticky}">
            <nav class="header-top flex-center nav-pc">
                <img src="../../src/assets/imgae/logo.svg"  @click="onToback">
                
                <ul class="flex-start MyHeader_content">
                    <li class="MyHeader_content-l"  :class="{'isMenu':indexMenu === index}" v-for="(item,index) in routesMenu" :key="index" @click="onMenu(index)">
                        
                        <router-link class="content-title" :to="item.path">
                            <span>{{ item.title }}</span>
                            <div v-show="index === 1 || index === 2" class="triangle" :class="{'istriangle':indexMenu === index}"></div>
                        </router-link>
                        <section v-show="item.childlevel && item.childlevel.length > 0" class="section-box" :style="{'visibility': sectionShow ? 'hidden' : 'visible'}">
                            <ul class="section-box-u">
                                <li class="flex-column" v-for="(v,i) in item.childlevel" :key="i" @click="onSection">
                                    <div class="linkd-box" :class="{'flag-btn' : index === 2  }">
                                        <router-link :to="{path:v.path, query: { param: v.title, identifying: i }}"><span>{{ v.title }}</span></router-link>
                                        <div v-show="index === 1" class="icon-arrow-right-r" :class="{'icon-arrow-right-r' : i !== 0}"></div>
                                    </div>
                                    <div class="section_z_box flex-column">
                                        <!-- <div class="section_yy" v-show="index === 1">应用案例</div> -->
                                        <div class="section_z_box_d" v-show="v.childlevel && v.childlevel.length > 0">
                                            <div class="section_z_box_falg" v-for="(o,j) in v.childlevel" :key="j">
                                                <router-link :to="{path: o.path, query: { param: o.title, identifying: i }}"><span>{{ o.title }}</span></router-link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </section>
                    </li>
                </ul>
            </nav>
            <div class="nav-app" :class="{'nav-menu-show':menuShow}">
                <div class="nav-layer"></div>
                <div class="nav-app-head flex-between">
                    <router-link to="/">
                        <img src="../../src/assets/imgae/logo-icon.svg">
                    </router-link>
                    <label class="toggle" :class="{'toggle-active':menuShow}" @click="menuShow = !menuShow">
                        <div class="bars"></div>
                        <div class="bars"></div>
                        <div class="bars"></div>
                    </label>
                </div>
                <div class="nav-app-menu" @touchmove.prevent>
                    <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
                        <el-submenu index="1">
                            <template v-slot:title>
                                <i class="el-icon-location"></i>
                                <span>导航一</span>
                            </template>
                            <el-menu-item-group>
                            <el-menu-item index="1-1">选项1</el-menu-item>
                            <el-menu-item index="1-2">选项2</el-menu-item>
                            </el-menu-item-group>
                            <el-menu-item-group title="分组2">
                            <el-menu-item index="1-3">选项3</el-menu-item>
                            </el-menu-item-group>

                        </el-submenu>
                        <el-menu-item index="2">
                            <template v-slot:title>
                                <span>导航二</span>
                            </template>
                        </el-menu-item>
                        <el-menu-item index="4">
                            <i class="el-icon-setting"></i>
                            <span>导航四</span>
                        </el-menu-item>
                    </el-menu>
                </div>
            </div>
        </header>
    </div>
    
</template>

<script>
import { ref, reactive, onMounted, watch, onBeforeUnmount,  } from "vue";
import { useRoute } from 'vue-router';

import { ElMenu, ElMenuItem, ElSubMenu} from 'element-plus';
 export default {
    name: 'NavigationBar',
    components: {
        'el-menu': ElMenu,
        'el-menu-item': ElMenuItem,
        'el-submenu': ElSubMenu
    },
    setup() {
        const menuShow = ref(false);
        const sectionShow = ref(false);
        const routesMenu = reactive([
            {
                title: '首页',
                path: '/',
                childlevel: []
            },
            {
                title: '解决方案',
                path: '',
                childlevel: [
                    {
                        title: "智慧铁路",
                        path: '/SolutionAssembly',
                        childlevel: [
                            {
                                title: "E路行",
                                path: '/SolutionAssembly',
                            },{
                                title: "电子考勤",
                                path: '/SolutionAssembly',
                            },
                            {
                                title: "绩效考核",
                                path: '/SolutionAssembly',
                            },
                            {
                                title: "人事管理",
                                path: '/SolutionAssembly',
                            },
                            {
                                title: "货运管理系统",
                                path: '/SolutionAssembly',
                            },
                        ]
                    },{
                        title: "智慧文旅",
                        path: '/SolutionAssembly',
                        childlevel: [
                            {
                                title: "景区综合票务管理平台",
                                path: '/SolutionAssembly',
                            }
                        ]
                    },{
                        title: "智慧工地",
                        path: '/SolutionAssembly',
                        childlevel: [
                            {
                                title: "智慧工地管理平台",
                                path: '/SolutionAssembly',
                            }
                        ]
                    },{
                        title: "智慧医院",
                        path: '/SolutionAssembly',
                        childlevel: [
                            {
                                title: "智慧医院安防管理平台",
                                path: '/SolutionAssembly',
                            }
                        ]
                    },
                ]
            },
            {
                title: '核心产品',
                path: '',
                childlevel: [
                    {
                        title: "人脸识别终端系列",
                        path: '/CoreProduct',
                        childlevel: [
                            {
                                title: "人脸门禁系列",
                                path: '/CoreProduct',
                            },{
                                title: "智慧防疫系列",
                                path: '/CoreProduct',
                            },{
                                title: "工地专用系列",
                                path: '/CoreProduct',
                            },
                        ]
                    },
                    {
                        title: "便携式终端系列",
                        path: '/CoreProduct',
                        childlevel: [
                            {
                                title: "刷卡检票设备系列",
                                path: '/CoreProduct',
                            },{
                                title: "扫码支付系列",
                                path: '/CoreProduct',
                            },{
                                title: "景区检票系列",
                                path: '/CoreProduct',
                            },
                        ]
                    },
                    {
                        title: "自助闸机系列",
                        path: '/CoreProduct',
                        childlevel: [
                            {
                                title: "自助闸机系列",
                                path: '/CoreProduct',
                            }
                        ]
                    },
                ]
            },
            {
                title: '关于我们',
                path: 'AboutView',
                childlevel: [],
            },
        ]);
        const route = useRoute()
        const isSticky = ref(false);
        const storedIndexMenu = ref(sessionStorage.getItem('indexMenu'));

        watch(route, (newValue) => {
            if(newValue.path === '/SolutionAssembly') {
                indexMenu.value = 1
                sessionStorage.setItem('indexMenu', indexMenu.value);
            }
            if(newValue.path === '/CoreProduct') {
                indexMenu.value = 2
                sessionStorage.setItem('indexMenu', indexMenu.value);
            }
        });
        const indexMenu = ref(storedIndexMenu.value !== null ? parseInt(storedIndexMenu.value) : 0) 
        

        const handleRouteChange = (to) => {
            if(to.path === '/SolutionView') {
                indexMenu.value = 1
                sessionStorage.setItem('indexMenu', indexMenu.value);
            }
        }

        const routeWatcher = watch(route, handleRouteChange)

        onBeforeUnmount(() => {
            routeWatcher() // 停止监听路由变化
        })
        const onMenu = (index) => {
            indexMenu.value = index
            sessionStorage.setItem('indexMenu', indexMenu.value);
        }
        onMounted(() => {
            window.addEventListener("scroll", ()=>{
                isSticky.value = window.scrollY > 0 ? true : false;
            })
        })
        const onSection = () => {
            sectionShow.value = true
            if(sectionShow.value){
                setTimeout(()=>{
                    sectionShow.value = false
                }, 50);
            }
        }
        const onToback = () => {
            window.location.href = '/';
            indexMenu.value = 0
            sessionStorage.setItem('indexMenu', indexMenu.value);
        }

        return {
            menuShow,
            routesMenu,
            isSticky,
            indexMenu,
            sectionShow,

            onMenu,
            onToback,
            onSection
        }
    }
 }
</script>

<style lang="scss" scoped>


.toggle {
  position: relative;
  width: 20px;
  height: 40px;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
//   gap: 5px;
  transition-duration: .5s;
  .bars {
    width: 100%;
    height: 2px;
    background-color: #333;
    border-radius: 4px;
    &:nth-child(1){
      width: 70%;
    }
    &:nth-child(2){
      transition-duration: .8s;
      margin: 4px 0px;
    }
    &:nth-child(3){
      width: 70%;
    }
  }
}
.toggle-active{
  transition-duration: .5s;
  transform: rotate(180deg);
  .bars{
    position: absolute;
    transition-duration: .5s;
    &:nth-child(1){
      width: 100%;
      transform: rotate(45deg);
      transition-duration: .5s;
    }
    &:nth-child(2){
      transform: scaleX(0);
      transition-duration: .5s;
    }
    &:nth-child(3){
      width: 100%;
      transform: rotate(-45deg);
      transition-duration: .5s;
    }
  }
}
.sticky{
//   position: sticky;
  color: #333;
  background: white;
  .nav-pc{

  }
}

    .navigation-bar {
        z-index: 100;
        width: 100%;
        color: #fff;
        background: white;
        position: fixed;
        top:0;
        left: 0;
        transition:all 0.6s ease;
        .header-top{
            width: 1200px;
            height: 83px;
            // padding-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            padding-left: 50px;
            padding-right: 128px;
            box-sizing: border-box;

            img{
                width: 80px;
                height: 80px;
            }
            .isMenu{
                color: #2C7EF9;
            }
            .istriangle{
                // background: #2C7EF9;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 8px solid #2C7EF9 !important;
                margin-top: 6px;
                margin-left: 6px;
            }
            .MyHeader_content {
                justify-content: space-between;
                margin-left: 150px;
                width: 792px;
                color: #2C2C34;
                font-size: 20px;
                .MyHeader_content-l{
                    position: relative;
                    user-select: none;
                    cursor: pointer;
                    display: flex;
                        align-items: center;
                    box-sizing: border-box;
                    &:hover{
                        transition: color .3s ease-in-out;
                    }
                    .content-title{
                        padding: 31px 24px 20px 24px;
                        display: flex;
                    }
                }
                
                
                .triangle {
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 8px solid #2C2C34;
                    margin-top: 6px;
                    margin-left: 6px;
                }
                li:hover .triangle {
                    -moz-transform:rotate(-180deg);
                    -webkit-transform:rotate(-180deg);
                    border-top: 8px solid #2C7EF9;

                }

                
                
            }
        }



        // 手机端的
        .nav-app{
            position: relative;
            width: 100vw;
            height: 60px;
            color: #333;
            .nav-app-head{
                position: fixed;
                z-index: 2;
                width: 100%;
                height: 60px;
                padding: 0 18px;
                box-sizing: border-box;
                .iconfont{
                    font-size: 20px;
                    font-weight: bold;
                }
                img{
                    width: 44px;
                    height: 44px;
                }
            }
            .nav-layer{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 0;
            background: #fff;
            transition: height .5s ease;
            }
            .nav-app-menu{
            overflow: hidden;
            font-size: 14px;
            position: fixed;
            width: 100%;
            height: calc(100vh - 60px);
            top: 60px;
            left: 0;
            opacity: 0;
            transform: scale(0);
            // transition: all .5s ease;
            transition: opacity .8s ease;
            transform-origin: 20px 0;
            ul{
                padding: 0 20px 20px;
                li{
                line-height: 54px;
                border-bottom: 1px solid #edf2fa;
                }
            }
            }
        }
        .nav-menu-show{
            .nav-layer{
            height: 100vh;
            }
            .nav-app-menu{
            opacity: 1;
            transform: scale(1);
            }
        }
        @include respond-to("phone") {
            .nav-pc{display: none;}
            .nav-app{display: block;}
        }
        @include respond-to("pad") {
            .nav-pc{display: none;}
            .nav-app{display: block;}
        }
        @include respond-to("notebook") {
            .nav-pc{display: none;}
            .nav-app{display: block;}
        }
        @include respond-to("desktop"){
            .nav-pc{display: flex;}
            .nav-app{display: none;}
        }
        @include respond-to("tv"){
            .nav-pc{display: flex;}
            .nav-app{display: none;}
        }
    }
    .MyHeader_content {
        position: relative;
        color: #2C7EF9;
        .MyHeader_content-l {
            &::after{
                content:"";
                width: 0;
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: #2C7EF9;
                transition: all .3s cubic-bezier(0.4,0.8,0.74,1);
                
            }
            &:hover{
                color: #2C7EF9;
                &::after{
                width: 100%;
                }
            }
        }
        
        li:hover .section-box {
            height: 260px;
            opacity: 1;
            transform: scale(1);
            visibility: visible;
            .section-box-u{
                
                display: flex;
                justify-content: center;
                color: #2C2C34;
            }
        }
    }
    .section-box{
        position: fixed;
        width: 100%;
        background: #FFFFFF;
        height: 0;
        top: 76px;
        left: 0;
        box-shadow: 0px 25px 45px 0px rgba(0, 0, 0, 0.1);
        visibility: hidden;
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.8s ease;
        transform-origin: 20px 0;
        display: flex;
        justify-content: center;
        // background: pink;
        .section-box-u{
            // visibility: hidden;
            width: 1350px;
            display: flex;
            justify-content: flex-end !important;
            // background: pink;
            padding-top: 20px;
            li{
                width: 170px;
                height: 200px;
                padding: 0;
                margin-right: 30px;
                
                .section_z_box {
                    width: 170px;
                    box-sizing: border-box;
                    .section_yy{
                        font-size: 10px;
                        color: #9E9E9E;
                        height: 20px;
                        margin-bottom: 8px;
                        margin-top: 15px;
                    }
                    .section_z_box_d{
                        font-size: 14px;
                        color: #212121;
                        width: 170px;
                        height: 0;
                        text-align: start;
                        padding-top: 10px;
                        .section_z_box_falg{
                            height: 20px;
                            color: #212121;
                            line-height: 20px;
                            margin-bottom: 12px;
                            padding: 2px 0px 2px 4px;

                        }
                    }
                    .section_z_box_falg:hover{
                        background: rgba(44, 126, 249, 0.1);
                        color: #2C7EF9;
                    }
                }
                
            }
            .linkd-box{
                    width: 170px;
                    height: 35px;
                    border-bottom: 1px solid #F5F5F5;
                    color: #2C2C34;
                    font-size: 16px;
                    font-weight: 500;
                }
            .linkd-box:hover{
                color: #2C7EF9;

                .icon-arrow-right-r::before,
                .icon-arrow-right-r::after {
                    background-color: #2C7EF9;
                }
                .icon-arrow-right::before,
                .icon-arrow-right::after {
                    background-color: #2C7EF9;
                }
            }
        }
    }
    
    .icon-arrow-right {
        width: 0px;
        height: 6px;
        position: relative;
        top: -8px;
        left: 118px;
        }

        .icon-arrow-right::before,
        .icon-arrow-right::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 1px;
            background-color: #2C2C34;
        }

        .icon-arrow-right::before {
            top: 0;
            right: 0;
            transform: rotate(45deg);
        }

        .icon-arrow-right::after {
            bottom: 0;
            right: 0;
            transform: rotate(-45deg);
        }

        .icon-arrow-right-r {
            width: 0px;
            height: 6px;
            position: relative;
            top: -10px;
            left: 78px;
        }
        .icon-arrow-right-r::before,
        .icon-arrow-right-r::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 1px;
            background-color: #2C2C34;
        }

        .icon-arrow-right-r::before {
            top: 0;
            right: 0;
            transform: rotate(45deg);
        }

        .icon-arrow-right-r::after {
            bottom: 0;
            right: 0;
            transform: rotate(-45deg);
        }

        .flag-btn{
            margin-bottom: 18px;
        }
        
</style>