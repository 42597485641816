import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueAnimateOnScroll from 'vue3-animate-onscroll';
import './styles/reset.css' //重置样式
import './styles/animista.css' //自定义动画样式
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'



const app = createApp(App)
app.use(router)
app.use(VueAnimateOnScroll)
app.mount('#app')